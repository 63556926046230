import { gql } from "@apollo/client";
import { IForm } from "types/Form.type";
import { IPerformanceEvaluation } from "types/PerformanceEvaluation/PerfromanceEvaluation.type";

export interface IGetPerformanceEvaluationsPaginatedInput {
  page: number;
  pageSize: number;
  searchText?: string;
}

export interface IPerformanceEvaluationPaginated {
  total: number;
  totalPages: number;
  performanceEvaluationsPaginated?: IPerformanceEvaluation[];
}

export interface IPerformanceEvaluationPaginated {
  getPerformanceEvaluationsPaginated?: IPerformanceEvaluationPaginated;
}

export interface IPerformanceEvaluationSimplify {
  id: string;
  userEvaluatedAcceptance?: boolean;
  markEvaluationAsFinished?: boolean;
  form: IForm;
}

export interface Answer {
  answerOptions: AnswerOption[];
  selectedAnswers: SelectedAnswer[];
}

export interface SelectedAnswer {
  answerId: string;
  optionId: string;
}

export interface AnswerOption {
  id: string;
  name: string;
  question: string;
}

export interface Form {
  id: string;
  questions: Question[];
}

export interface Question {
  id: string;
  name: string;
  type: string;
  formQuestionLikertScaleOptions: FormQuestionLikertScaleOption[];
  formQuestionOptions: FormQuestionOption[];
  details: {
    endText: string;
    startText: string;
  };
}

export interface FormQuestionLikertScaleOption {
  name: string;
  id: string;
}

export interface LikertAnswer {
  optionId: string;
  optionName: string;
}

export interface FormQuestionOption {
  id: string;
  name: string;
  question: string;
}

export interface FormAnswersResponse {
  getFormReponseIdByFormAndResponseId: FormAnswers;
}

export interface FormAnswers {
  answers: number;
  questions: Question[];
}

export interface Question {
  id: string;
  type: string;
  order: number;
  question: string;
  required: boolean;
  likertAnswers: LikertAnswer[];
  details: {
    endText: string;
    startText: string;
  };
  answers: Answer[];
  options: Option[];
}

export interface LikertAnswer {
  optionName: string;
  optionId: string;
  usersResponded: number;
  likertSumary: LikertSumary[];
}

export interface LikertSumary {
  answerName: string;
  answerId: string;
  quantity: number;
  percentage: number;
}

export interface Answer {
  answer: string;
  userName: string;
  userLastName: string;
  userProfileImage: string;
  userEmail: string;
  userId: string;
  leader: any;
}

export interface Option {
  id: string;
  name: string;
  quantity: number;
}

const GET_PERFORMANCE_EVALUATION_PAGINATED = gql`
  query GetPerformanceEvaluationsPaginated(
    $getPerformanceEvaluationPaginatedInput: PerformanceEvaluationPaginatedInput!
  ) {
    getPerformanceEvaluationsPaginated(
      GetPerformanceEvaluationPaginatedInput: $getPerformanceEvaluationPaginatedInput
    ) {
      total
      totalPages
      performanceEvaluationsPaginated {
        id
        overallEvaluationStatus
        leadershipEvaluationStatus
        leadershipEvaluationSendedAt
        selfEvaluationStatus
        selfEvaluationSendedAt
        createdBy {
          name
          lastname
          email
          profileImage
          role {
            name
          }
        }
        leadershipEvaluation {
          endDate
          evaluation
          startDate
        }
        selfEvaluation {
          endDate
          evaluation
          startDate
        }
        createdAt
        name
      }
    }
  }
`;

const GET_ACTIVE_PERFORMANCE_EVALUATION = gql`
  query GetSelfEvaluation {
    getSelfEvaluation {
      id
      performanceEvaluation {
        id
        name
        selfEvaluation {
          endDate
          startDate
          evaluation
        }
        overallEvaluationStatus
      }
    }
  }
`;

const GET_HISTORIC_PERFORMANCE_EVALUATION = gql`
  query GetHistoricSelfEvaluations {
    getHistoricSelfEvaluations {
      id
      status
      performanceEvaluation {
        id
        name
        selfEvaluationStatus
        selfEvaluation {
          endDate
          startDate
          evaluation
        }
      }
    }
  }
`;

const GET_MY_LEADERSHIP_EVALUATIONS = gql`
  query GetMyLeaderShipEvaluations {
    getMyLeaderShipEvaluations {
      id
      performanceEvaluationName
      updatedAt
      status
      userEvaluatedAcceptance
      markEvaluationAsFinished
      form {
        id
      }
    }
  }
`;

const GET_LEADERSHIP_EVALUATIONS = gql`
  query GetLeaderShipEvaluations($teamId: String!) {
    getLeaderShipEvaluations(teamId: $teamId) {
      id
      status
      userEvaluated {
        id
        name
        lastname
        email
        profileImage
        role {
          name
        }
        seniority
      }
      userEvaluatedAcceptance
      leaderLastComments
      markEvaluationAsFinished
      updatedAt
      performanceEvaluation {
        id
        name
        leadershipEvaluationStatus
        leadershipEvaluation {
          endDate
          startDate
          evaluation
        }
      }
    }
  }
`;

export default {
  GET_PERFORMANCE_EVALUATION_PAGINATED,
  GET_ACTIVE_PERFORMANCE_EVALUATION,
  GET_HISTORIC_PERFORMANCE_EVALUATION,
  GET_LEADERSHIP_EVALUATIONS,
  GET_MY_LEADERSHIP_EVALUATIONS,
};
