import { gql } from "@apollo/client";
import { IAppreciation } from "types/Appreciation/Appreciation.types";
import { IAppreciationDistribute } from "types/Appreciation/AppreciationDistribute.type";
import { IAppreciationQuestion } from "types/Appreciation/AppreciationQuestion.type";

export interface IGetAppreciationsPaginatedInput {
  name?: string;
  dimensions?: string[];
  limit: number;
  offset: number;
  filter?: string;
}

const GET_APPRECIATIONS_PAGINATED = gql`
  query GetAppreciationsPaginated(
    $getAppreciationsPaginatedInput: GetAppreciationsPaginatedInput!
  ) {
    getAppreciationsPaginated(
      getAppreciationsPaginatedInput: $getAppreciationsPaginatedInput
    ) {
      appreciations {
        id
        name
        description
        creator {
          id
          name
          lastname
          email
          profileImage
          role {
            id
            name
          }
        }
        isQuickie
        enableForPreselection
        questions
        thumbnail
        dimensions {
          id
          name
          originalId
        }
        distributed
        hidden
      }
      totalPages
      total
    }
  }
`;

export interface IGetAppreciationPaginatedResponse {
  appreciations: IAppreciation[];
  totalPages: number;
  total: number;
}

export interface IGetAppreciationsInput {
  name?: string;
}

const GET_APPRECIATIONS_LIGHT = gql`
  query appreciations($getAppreciationsInput: GetAppreciationsInput!) {
    appreciations(getAppreciationsInput: $getAppreciationsInput) {
      id
      name
      description
      creator {
        id
        name
        lastname
        email
        profileImage
        role {
          id
          name
        }
      }
      distributed
      isQuickie
      enableForPreselection
      questions
      thumbnail
      dimensions {
        id
        name
        originalId
      }
    }
  }
`;

export interface IAppreciationsResponse {
  getAppreciationsByDimensions: IAppreciation[];
}

export interface IGetAppreciationsDimensionsInput {
  dimensions?: string[];
}

const GET_APPRECIATIONS_DIMENSIONS = gql`
  query GetAppreciationsByDimensions(
    $getAppreciationsByDimensionInput: GetAppreciationsByDimensionInput!
  ) {
    getAppreciationsByDimensions(
      getAppreciationsByDimensionInput: $getAppreciationsByDimensionInput
    ) {
      id
      name
      description
      creator {
        id
        name
        lastname
        email
        profileImage
        role {
          id
          name
        }
      }
      distributed
      isQuickie
      enableForPreselection
      questions
      thumbnail
      dimensions {
        id
        name
        originalId
      }
    }
  }
`;

const GET_APPRECIATIONS = gql`
  query appreciations($getAppreciationsInput: GetAppreciationsInput!) {
    appreciations(getAppreciationsInput: $getAppreciationsInput) {
      id
      associatedActionPlans
      periodicityNotificationCoach
      periodicityNotificationUser
      timeToAnswer
      name
      hidden
      distributed
      description
      dimensions {
        id
        name
        originalId
      }
      startDate
      endDate
      vendor {
        name
      }
      active
      type
      periodicity
      team {
        id
        name
      }
      isQuickie
      enableForPreselection
      creator {
        id
        name
        lastname
        profileImage
        email
        role {
          id
          name
        }
      }
    }
  }
`;

export interface IAppreciationById {
  id: string;
}

const GET_APPRECIATION = gql`
  query appreciation($id: String!) {
    appreciation(id: $id) {
      id
      active
      name
      description
      startDate
      endDate
      periodicity
      type
      distributed
      thumbnail
      dimensions {
        id
        name
        originalId
      }
      active
    }
  }
`;

export interface IAppreciationQuestionOptionsByAppreciationId {
  appreciationId: string | string[];
}

export interface IAppreciationQuestionOptionsResponse {
  appreciationQuestions: IAppreciationQuestion[];
}

const GET_APPRECIATION_QUESTIONS = gql`
  query appreciationQuestions($appreciationId: String!) {
    appreciationQuestions(appreciationId: $appreciationId) {
      id
      name
      maturityId
      createdAt
      updatedAt
      dimension {
        id
        name
      }
      visibleOptions
      correctOptions
      options {
        id
        name
        isCorrect
      }
      tags
      createdBy {
        id
        name
        lastname
        email
        profileImage
      }
      links {
        name
        url
      }
    }
  }
`;

export interface IAppreciationDistributes {
  userAppreciation: IAppreciationDistribute[];
}

const GET_USER_APPRECIATIONS = gql`
  query userAppreciation {
    userAppreciation {
      id
      iteration
      activeQuestionIndex
      completed
      startDate
      endDate
      isAvailable
      timeToAnswer
      remindedAt
      appreciation {
        id
        name
        description
        dimensions {
          name
        }
      }
      started
      questions {
        id
        answered
        name
        options {
          name
        }
      }
      user {
        id
      }
    }
  }
`;

const GET_USER_APPRECIATION_BY_ID = gql`
  query appreciationDistributeById($id: String!) {
    appreciationDistributeById(id: $id) {
      id
      activeQuestionIndex
      user {
        name
        lastname
        role {
          name
        }
      }
      distributedBy {
        name
        lastname
        email
        profileImage
        role {
          name
        }
      }
      completed
      isAvailable
      timeToAnswer
      startDate
      startedAt
      completedAt
      endDate
      maxQuestions
      appreciation {
        id
        name
        description
        dimensions {
          id
          name
        }
        thumbnail
      }
      started
      userMaturityLevelByDimension {
        dimensionName
        maturityname
        percent
      }
      questions {
        id
        answered
        answeredAt
        originalId
        correctOptions
        name
        options {
          originalId
          name
          checked
        }
      }
    }
  }
`;

const GET_USER_APPRECIATION_COMPLETED_BY_ID = gql`
  query appreciationDistributeById($id: String!) {
    appreciationDistributeById(id: $id) {
      id
      activeQuestionIndex
      completed
      startedAt
      completedAt
      isAvailable
      remindedAt
      distributeId
      appreciation {
        id
        name
        description
        dimensions {
          id
          name
        }
      }
      userGeneralMaturityLevel
      started
      userMaturityLevelByDimension {
        dimensionId
        dimensionName
        maturityname
        percent
      }
      questions {
        id
        answered
        answeredAt
        originalId
        name
        dimension
        isCorrect
        options {
          originalId
          name
          checked
        }
        links {
          url
          name
        }
      }
    }
    user {
      id
    }
  }
`;

const GET_APPRECIATIONS_DISTRIBUTE_QUERY_USER_LOG_BY_USER_ID = gql`
  query appreciationDistributeQueryUserLogByUserId($id: String!) {
    appreciationDistributeQueryUserLogByUserId(id: $id) {
      appreciation {
        name
      }
      startDates
      endDates
      distributeFriendlyName
      userMaturityLevelByDimension {
        dimensionName
        maturityname
        percent
      }
    }
  }
`;

const GET_APPRECIATIONS_DISTRIBUTE_QUERY_LOG_BY_TEAM_ID = gql`
  query appreciationDistributeQueryLogByTeamId($id: String!) {
    appreciationDistributeQueryLogByTeamId(id: $id) {
      id
      appreciation {
        name
      }
      startDates
      endDates
      distributeId
      distributeName
      distributeFriendlyName
      userMaturityLevelByDimension {
        dimensionName
        maturityname
        percent
        distributeId
      }
    }
  }
`;

export interface IAppreciationAffectedUsersInput {
  appreciationId: string;
  team?: string;
}
const GET_APPRECIATION_AFFECTED_USERS = gql`
  query affectedUsers(
    $appreciationAffectedUsersInput: AppreciationAffectedUsersInput!
  ) {
    affectedUsers(
      appreciationAffectedUsersInput: $appreciationAffectedUsersInput
    ) {
      id
      name
      email
      lastname
      profileImage
      systemRole
      role {
        id
        name
        avatar
      }
    }
  }
`;

export interface IAppreciationSelectedUsersInput {
  appreciationId: string;
  distributeId?: string;
}

const GET_APPRECIATION_SELECTED_USERS = gql`
  query appreciationSelectedUsers(
    $appreciationSelectedUsersInput: AppreciationSelectedUsersInput!
  ) {
    appreciationSelectedUsers(
      appreciationSelectedUsersInput: $appreciationSelectedUsersInput
    ) {
      user {
        id
        name
        lastname
        email
        profileImage
        lastActivityDate
        role {
          id
          name
        }
      }
      appreciationDistributes {
        id
        associatedActionPlans
        iteration
        activeQuestionIndex
        completed
        startDate
        remindedAt
        endDate
        isAvailable
        appreciation {
          id
          name
          description
          dimensions {
            name
          }
        }
        started
        questions {
          id
          answered
          name
          options {
            name
          }
        }
      }
    }
  }
`;

export interface ICheckCanDistributeAppreciationInput {
  appreciationId: string;
  team?: string;
  startDate?: Date;
  endDate?: Date;
}

const CHECK_CAN_DISTRIBUTE_APPRECIATION = gql`
  query checkCanDistributeAppreciation(
    $checkCanDistributeAppreciationInput: CheckCanDistributeAppreciationInput!
  ) {
    checkCanDistributeAppreciation(
      checkCanDistributeAppreciationInput: $checkCanDistributeAppreciationInput
    )
  }
`;

const GET_APPRECIATION_REPORT = gql`
  query Query($appreciationId: String!, $distributeId: String!) {
    appreciationReport(
      appreciationId: $appreciationId
      distributeId: $distributeId
    )
  }
`;

const GET_APPREACTIONS_BY_USER = gql`
  query UserAppreciationByVendor($userId: String!, $vendorId: String!) {
    userAppreciationByVendor(userId: $userId, vendorId: $vendorId) {
      id
      activeQuestionIndex
      completed
      isAvailable
      timeToAnswer
      startDate
      startedAt
      completedAt
      endDate
      appreciation {
        id
        name
        description
        dimensions {
          id
          name
        }
      }
      started
      userMaturityLevelByDimension {
        dimensionName
        maturityname
        percent
      }
      userGeneralMaturityLevel
      questions {
        id
        answered
        answeredAt
        originalId
        correctOptions
        name
        options {
          originalId
          name
          checked
        }
      }
    }
  }
`;

const GET_APPRECIATION_DISTRIBUTE_GROUP = gql`
  query AppreciationDistributeGroup($appreciationId: String!) {
    appreciationDistributeGroup(appreciationId: $appreciationId) {
      appreciation {
        id
        name
      }
      appreciationDistributes {
        name
        id
        createdAt
        distributeId
        remindedAt
        distributedBy {
          id
          name
          lastname
          email
          profileImage
        }
        appreciationDistributeDetails {
          id
          name
        }
        team {
          id
          name
        }
      }
      distributeId
      firstDate
      lastDate
    }
  }
`;

const GET_APPRECIATION_DISTRIBUTED_PAGINATED = gql`
  query AppreciationDistributedPaginated(
    $appreciationDistributedPaginatedInput: AppreciationDistributedPaginatedInput!
  ) {
    appreciationDistributedPaginated(
      appreciationDistributedPaginatedInput: $appreciationDistributedPaginatedInput
    ) {
      total
      userAppreciationDistributed {
        appreciation {
          id
          name
        }
        appreciationDistributes {
          name
          id
          createdAt
          distributeId
          remindedAt
          distributedBy {
            id
            name
            lastname
            email
            profileImage
          }
          appreciationDistributeDetails {
            id
            name
          }
          team {
            id
            name
          }
        }
        distributeId
        firstDate
        lastDate
      }
    }
  }
`;

const GET_APPRECIATION_RANKING = gql`
  query AppreciationRanking(
    $appreciationRankingInput: AppreciationRankingInput!
  ) {
    appreciationRanking(appreciationRankingInput: $appreciationRankingInput) {
      userId
      userName
      userLastname
      userProfileImage
      userEmail
      position
      totalPercent
      started
    }
  }
`;

export default {
  GET_APPRECIATIONS,
  GET_APPRECIATION,
  GET_APPRECIATION_QUESTIONS,
  GET_USER_APPRECIATIONS,
  GET_USER_APPRECIATION_BY_ID,
  GET_USER_APPRECIATION_COMPLETED_BY_ID,
  GET_APPRECIATIONS_DISTRIBUTE_QUERY_USER_LOG_BY_USER_ID,
  GET_APPRECIATIONS_DISTRIBUTE_QUERY_LOG_BY_TEAM_ID,
  GET_APPRECIATION_AFFECTED_USERS,
  GET_APPRECIATION_SELECTED_USERS,
  CHECK_CAN_DISTRIBUTE_APPRECIATION,
  GET_APPRECIATION_REPORT,
  GET_APPREACTIONS_BY_USER,
  GET_APPRECIATION_DISTRIBUTE_GROUP,
  GET_APPRECIATION_RANKING,
  GET_APPRECIATIONS_LIGHT,
  GET_APPRECIATIONS_PAGINATED,
  GET_APPRECIATIONS_DIMENSIONS,
  GET_APPRECIATION_DISTRIBUTED_PAGINATED,
};
