"use client";

import { WIDTH_XL } from "@constants/global";
import { useWindowWidth } from "@react-hook/window-size";
import { IMenuList } from "components/Layout/ManagerProLayout/utils";
import { useTemplateContext } from "context/template/TemplateContext";
import { useIsScreenMd } from "hooks/useIsScreenMd";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { IRoute } from "routes/types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "shadcn/components/ui/tooltip";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import { TemplateType } from "stores/template/useTemplateStore";
import { ICONS_ACTIVE_VERSION, isSvg } from "../content/utils/iconModulesPath";

interface MenuItemProps {
  item: IMenuList;
  onClick: (path: string) => void;
  checkSubRoutesRoleGuard: (routes: IRoute[]) => boolean;
  setIsHoverItem: (value: boolean) => void;
  render?: boolean;
}

export default function MenuItem(props: MenuItemProps) {
  const {
    item,
    onClick,
    checkSubRoutesRoleGuard,
    setIsHoverItem,
    render = true,
  } = props;
  const router = useRouter();
  const { isExpanded } = useDesktopSidebarStore();
  const [renderText, setRenderText] = useState(true);
  const isScreenMd = useIsScreenMd();
  const wWidth = useWindowWidth();
  const is720 = wWidth < WIDTH_XL;
  const { template } = useTemplateContext();

  useEffect(() => {
    if (isExpanded) {
      setRenderText(true);
    }
    if (!isExpanded && isScreenMd) {
      setTimeout(() => {
        setRenderText(false);
      }, 200);
    }
  }, [isExpanded, isScreenMd, is720]);

  const handleMouseEnter = () => {
    setIsHoverItem(true); // Actualizar estado a 'close' al salir del hover
  };

  if (!render) return null;

  return item.route && !checkSubRoutesRoleGuard([item.route]) ? (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger
          className={`flex h-sidebar-button w-full items-center gap-4 pl-6 ${router.pathname !== item.route.path && "hover:bg-gray-100 hover:dark:bg-slate-800"} ${router.pathname === item.route.path && "active bg-gray-200 dark:bg-gray-800"} ${template === TemplateType.Aurora && router.pathname === item.route.path && "rounded-2xl"} ${template === TemplateType.Aurora && router.pathname !== item.route.path && "hover:rounded-2xl"} `}
          onClick={() => item.route && onClick(item.route.path)}
          onMouseOver={handleMouseEnter}
        >
          <>
            <picture className="relative grid min-h-sidebar-icon min-w-sidebar-icon">
              <Image
                src={item.urlIcon}
                fill
                sizes="w-sidebar-icon"
                className={`h-sidebar-icon w-sidebar-icon object-contain ${ICONS_ACTIVE_VERSION() === "v2" && "opacity-50 dark:invert"}`}
                alt={item.name}
                priority
                unoptimized={isSvg(item.urlIcon)}
              />
            </picture>

            {renderText && (
              <span className="block overflow-hidden truncate text-wrap text-left text-sidebar">
                {item.name}
              </span>
            )}
          </>
        </TooltipTrigger>
        {!isExpanded && isScreenMd && (
          <TooltipContent
            side="right"
            className="dark:border-gray-600 dark:bg-gray-900"
          >
            <p> {item.name}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  ) : null;
}
