import { gql } from "@apollo/client";
import { IPerformanceEvaluationForm } from "types/PerformanceEvaluation/PerfromanceEvaluation.type";

export interface ICreatePerformanceEvaluationInput {
  name: string;
  selfEvaluation?: IPerformanceEvaluationForm;
  leadershipEvaluation?: IPerformanceEvaluationForm;
}

export interface IUpdatePerformanceEvaluationInput
  extends ICreatePerformanceEvaluationInput {
  id: string;
}

const CREATE_PERFORMANCE_EVALUATION = gql`
  mutation CreatePerformanceEvaluation(
    $createPerformanceEvaluationInput: CreatePerformanceEvaluationInput!
  ) {
    createPerformanceEvaluation(
      createPerformanceEvaluationInput: $createPerformanceEvaluationInput
    ) {
      name
    }
  }
`;

const UPDATE_PERFORMANCE_EVALUATION = gql`
  mutation UpdatePerformanceEvaluation(
    $updatePerformanceEvaluationInput: UpdatePerformanceEvaluationInput!
  ) {
    updatePerformanceEvaluation(
      updatePerformanceEvaluationInput: $updatePerformanceEvaluationInput
    ) {
      id
    }
  }
`;

const DELETE_PROCESS_EVALUATION = gql`
  mutation DeletePerformanceEvaluation(
    $deletePerformanceEvaluationId: String!
  ) {
    deletePerformanceEvaluation(id: $deletePerformanceEvaluationId)
  }
`;

export default {
  CREATE_PERFORMANCE_EVALUATION,
  UPDATE_PERFORMANCE_EVALUATION,
  DELETE_PROCESS_EVALUATION,
};
