import { Button } from "@/components/ui/button";
import { WIDTH_XL } from "@constants/global";
import { useWindowWidth } from "@react-hook/window-size";
import { PanelLeftClose } from "lucide-react";
import { useEffect } from "react";
import { useDesktopSidebarStore } from "stores/sidebar/useDesktopSidebarStore";
import SidebarContent from "./components/body/content/SidebarContent";
import FooterVersion from "./components/footer/ui/FooterVersion";
import WhatIsNew from "./components/footer/ui/WhatIsNew";

interface SidebarV2Props {
  readonly render?: boolean;
}

export default function SidebarV2(props: SidebarV2Props) {
  const { isExpanded, setIsExpanded, toggleSidebar } = useDesktopSidebarStore();
  const wWidth = useWindowWidth();
  const is720 = wWidth < WIDTH_XL;
  useEffect(() => {
    if (is720) {
      setIsExpanded(false);
    }
  }, [is720, wWidth, isExpanded]);

  const { render = true } = props;

  if (!render) {
    return null;
  }
  return (
    <>
      <div
        className={`container-sidebar z-10 grid content-start border bg-white transition-all duration-300 ease-in-out dark:border-gray-800 dark:bg-gray-900 ${
          isExpanded
            ? "w-[var(--sidebar-width-expanded)] max-w-[var(--sidebar-width-expanded)]"
            : "w-[var(--sidebar-width-collapsed)] max-w-[var(--sidebar-width-collapsed)]"
        } `}
      >
        {/*<div className="sticky top-0 z-5 grid min-h-[56px] w-full gap-2 bg-white px-4 py-2 shadow dark:bg-gray-900">
          <TagVendor />
           <Separator className="mb-1 bg-gray-400 dark:bg-gray-600" /> 
        </div>*/}

        <div className="grid overflow-y-auto px-1 pb-[132px] pt-2">
          <SidebarContent />
        </div>

        <div className="absolute bottom-0 z-5 grid w-full gap-2 bg-white shadow-custom-top dark:bg-gray-900">
          <div className="relative grid min-h-[114px] gap-1 py-6">
            <Button
              variant="outline"
              className="absolute -top-4 left-6 h-8 min-h-8 w-8 min-w-8 rounded-full p-0 dark:bg-gray-900"
              onClick={toggleSidebar}
            >
              <PanelLeftClose
                strokeWidth={1}
                className={`transition-transform duration-200 ${isExpanded ? "rotate-0" : "rotate-180"}`}
              />
            </Button>
            <WhatIsNew />
            <FooterVersion />
          </div>
        </div>
      </div>

      <style jsx>{`
        .container-sidebar {
          height: calc(100dvh - var(--sidebar-header-mobile));
          margin: 1.5rem 0;
          border-radius: 18px;
          width: ${isExpanded
            ? "var(--sidebar-width-expanded)"
            : "var(--sidebar-width-collapsed)"};
          min-width: ${isExpanded
            ? "var(--sidebar-width-expanded)"
            : "calc(var(--sidebar-width-collapsed) + 8px)"};
          position: relative;
          display: grid;
          overflow: hidden;
        }
        @media (min-width: 769px) {
          .container-sidebar {
            height: calc(100vh - var(--sidebar-header-desktop) - 3rem);
          }
        }
      `}</style>
    </>
  );
}
