import { gql } from "@apollo/client";
import { IBase } from "types/Base";
import {
  EFormResponseStatusType,
  Form,
  FormsPaginated,
  GetFormResponse,
  GetFormResponseByFormId,
  IFormQuestion,
} from "types/Form.type";
import {
  EPerformanceEvaluationType,
  IPerformanceEvaluation,
} from "types/PerformanceEvaluation/PerfromanceEvaluation.type";
import { IUser } from "types/User.types";

export interface GetFormResponsePagination {
  getFormResponsePagination: GetFormResponse;
}

const MYFORMS = gql`
  query getFormResponsePagination(
    $offset: Int
    $limit: Int
    $name: String
    $status: EFormResponseStatusType
    $createdBy: Boolean
  ) {
    getFormResponsePagination(
      offset: $offset
      limit: $limit
      name: $name
      status: $status
      createdBy: $createdBy
    ) {
      data {
        id
        user {
          email
        }
        form {
          id
          name
          isAnonymous
          thumbnail
          questions {
            id
            type
          }
          createdBy {
            id
            name
            lastname
            email
            profileImage
          }
        }
        status
        createdAt
      }
      total
      totalPages
    }
  }
`;

const FORM_RESPONSES = gql`
  query getFormResponsePagination(
    $offset: Int
    $limit: Int
    $name: String
    $status: EFormResponseStatusType
    $createdBy: Boolean
    $formId: String
  ) {
    getFormResponsePagination(
      offset: $offset
      limit: $limit
      name: $name
      status: $status
      createdBy: $createdBy
      formId: $formId
    ) {
      data {
        id
        email
        form {
          id
          isAnonymous
          name
          questions {
            id
            type
          }
        }
        status
        user {
          email
        }
        isAnonymous
        sentiment
        sentimentScore
        sharedUsers
        createdAt
      }
      total
      totalPages
    }
  }
`;

export interface IFormResponse extends IBase {
  user: IUser;
  form: Form;
  answers: IFormQuestion[];
  status: EFormResponseStatusType;
  hidden?: boolean;
  creator?: string;
  email?: string;
  sentiment?: string;
  sentimentScore?: number;
  thumbnail?: string;
  isAnonymous?: boolean;
  isPerformanceEvaluation?: boolean;
  sharedUsers?: string[];
  sharedUser?: string;
  userEvaluated?: IUser;
  performanceEvaluation?: IPerformanceEvaluation;
  performanceEvaluationType?: EPerformanceEvaluationType;
  teamId?: string;
  markEvaluationAsFinished?: boolean;
  userEvaluatedComment?: string;
  leaderLastComments?: string;
}

const FORM = gql`
  query Form($id: String!) {
    form(id: $id) {
      id
      name
      description
      hidden
      enableSentimentAnalysis
      originalId
      isAnonymous
      thumbnail
      isFormShared
      isPerformanceEvaluation
      questions {
        id
        name
        type
        required
        order
        details {
          startText
          endText
        }
        formQuestionOptions {
          id
          name
        }
        formQuestionLikertScaleOptions {
          id
          name
        }
        questionImage
      }
    }
  }
`;

export interface IGetFormsPaginatedInput {
  name: string;
  limit: number;
  offset: number;
  isPerformanceEvaluation?: boolean;
}

export interface IFormsPaginatedResponse {
  formsPaginated: FormsPaginated;
}

const FORMS = gql`
  query FormsPaginated($getFormsPaginatedInput: GetFormsPaginatedInput!) {
    formsPaginated(getFormsPaginatedInput: $getFormsPaginatedInput) {
      data {
        id
        createdAt
        name
        description
        hidden
        thumbnail
        enableSentimentAnalysis
        isFormShared
        isAnswered
        isAnonymous
        isPerformanceEvaluation
        createdBy {
          id
          name
          lastname
          email
          profileImage
        }
        questions {
          id
        }
        isQuickie
      }
      total
      totalPages
    }
  }
`;

export interface IGetFormResponseByFormIdResponse {
  getFormResponseByFormId: GetFormResponseByFormId;
}

const GET_FORM_BY_FORM_AND_RESPONSE_ID = gql`
  query GetFormReponseIdByFormAndResponseId(
    $responseId: String!
    $formId: String!
  ) {
    getFormReponseIdByFormAndResponseId(
      responseId: $responseId
      formId: $formId
    ) {
      answers
      questions {
        id
        type
        order
        question
        required
        likertAnswers {
          optionName
          optionId
          usersResponded
          likertSumary {
            answerName
            answerId
            quantity
            percentage
          }
        }
        details {
          startText
          endText
        }
        answers {
          answer
          userName
          userLastName
          userProfileImage
          userEmail
          userId
          leader {
            id
            name
            lastname
            email
            profileImage
          }
        }
        options {
          id
          name
          quantity
        }
      }
    }
  }
`;

const GET_FORM_RESPONSES = gql`
  query GetFormResponseByFormId($id: ID!) {
    getFormResponseByFormId(id: $id) {
      answers
      questions {
        id
        type
        order
        question
        required
        likertAnswers {
          optionName
          optionId
          usersResponded
          likertSumary {
            answerName
            answerId
            quantity
            percentage
          }
        }
        details {
          startText
          endText
        }
        answers {
          answer
          userName
          userLastName
          userProfileImage
          userEmail
          userId
          leader {
            id
            name
            lastname
            email
            profileImage
          }
        }
        options {
          id
          name
          quantity
        }
      }
    }
  }
`;

const GET_FORM_RESPONSES_FILE = gql`
  query Query($getCsvFormResponseId: ID!) {
    getCsvFormResponse(id: $getCsvFormResponseId)
  }
`;

const GET_FORM_RESPONSE_BY_ID = gql`
  query FormResponseById($formResponseByIdId: String!) {
    formResponseById(id: $formResponseByIdId) {
      form {
        id
        name
        description
        hidden
        enableSentimentAnalysis
        isAnonymous
        questions {
          id
          name
          type
          required
          order
          details {
            startText
            endText
          }
          formQuestionOptions {
            id
            name
          }
          formQuestionLikertScaleOptions {
            id
            name
          }
        }
      }
      status
      isPerformanceEvaluation
      performanceEvaluationType
      userEvaluated {
        id
        name
        lastname
        profileImage
        email
        role {
          id
          name
        }
      }
    }
  }
`;

const GET_LEADERS_FORMS = gql`
  query GetLeaderSelected {
    getLeaderSelected {
      id
      name
      lastname
      profileImage
      email
    }
  }
`;

const GET_PERFORMANCE_EVALUATIONS = gql`
  query GetFormsPerformanceEvaluation {
    getFormsPerformanceEvaluation {
      id
      name
    }
  }
`;

export default {
  MYFORMS,
  FORMS,
  FORM,
  GET_FORM_RESPONSES,
  GET_FORM_RESPONSES_FILE,
  GET_FORM_RESPONSE_BY_ID,
  FORM_RESPONSES,
  GET_LEADERS_FORMS,
  GET_PERFORMANCE_EVALUATIONS,
  GET_FORM_BY_FORM_AND_RESPONSE_ID,
};
